/* eslint-disable camelcase, no-undef,no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from "lodash";
import { ScoreBoard, QuestionContainer, QuizContainer, OptionsBlock, Flex } from './styles';
import { Layout, Title, AdventureTitle, TitleContainer } from '../Article/styles';
import { CheckMark } from '../CreateEvent/styles';
import { StyledCheckBox } from '../RegistrationWellnessInterests/styles';
import { ArticleButtonsContainer, BackButton } from '../Journeys/styles';
import { NoComments } from '../SocialFeeds/styles';
import Waiting from '../Waiting';
import { FetchQuizzes, SubmitQuiz, getAdventureArticle } from '../../redux/actions';
import CommonButton from '../common/CommonButton/CommonButton';

class ArticleQuiz extends Component {
  constructor(props){
    super(props);
    this.state = {
      quizQuestions: null,
      optionSelection: [],
      buttonDisable: true,
    }
  }

  componentDidMount() {
    window.scrollTo(0,0);
    const {fetchQuizzes, match, fetchAdventureArticle } = this.props;
    const categoryName = match.params.categoryName;
    const adventureId = match.params.id;
    fetchAdventureArticle(adventureId, categoryName.toLowerCase() || 1);
    fetchQuizzes(adventureId, 0);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { quizQuestions } = nextProps;
    if(quizQuestions) {
      let arr = [];
      for(let i=0; i<quizQuestions.length; i++) {
        let obj = {};
        obj['quiz_id'] = quizQuestions[i].id;
        obj['choices'] = [];
        arr.push(obj);
      }
      this.setState({ quizQuestions, optionSelection: arr });
    }
  }

  disablebutton = () => {
    const { quizQuestions, optionSelection } = this.state;
    let bool = false;
    for(let i=0; i<quizQuestions.length; i++) {
      if(_.isEmpty(optionSelection[i].choices)) {
        bool = true;
      }
    }
    this.setState({ buttonDisable: bool })
  };

  submitQuiz = () => {
    let obj = {};
    const { adventureArticle, history, submitQuiz } = this.props;
    const { optionSelection } = this.state;
    const stringArray = history.location.pathname.split('/');
    obj['journey_id'] = adventureArticle.id;
    obj['quiz'] = optionSelection;
    submitQuiz(obj, stringArray[3], history.push);
  };

  markAnswer = (optionId, questionIndex, list) => {
    const dupQuestion = this.state.optionSelection;
    if (list && list.no_of_correct_choices === 1) {
      let choiceSelection = [];
      choiceSelection.push(optionId);
      dupQuestion[questionIndex]['choices'] = choiceSelection;
      this.setState({optionSelection: dupQuestion}, () => this.disablebutton());
    }
    else{
      let arr = dupQuestion[questionIndex].choices;
      let index = arr.indexOf(optionId);
      if(index === -1){
        arr.push(optionId);
      }
      else{
        arr.splice(index,1);
      }
      dupQuestion[questionIndex]['choices'] = arr;
      this.setState({optionSelection: dupQuestion}, () => this.disablebutton());
    }
  };
  multipleChoices = (id, questionIndex) => {
    let arr = this.state.optionSelection[questionIndex].choices;
    let index = arr.indexOf(id);
    if(index !== -1)
      return true;
    else
      return false;
  };
  quizDashBoard = () => (
    this.state.quizQuestions.map((list, questionIndex) => (
      <QuestionContainer key={questionIndex}>
        <Flex >
          <div className="question"> {(questionIndex+1)+'. '+list.quiz}</div>
        </Flex>
        { list.choices.map((option, optionIndex) => (
          <OptionsBlock key={optionIndex}>
            <StyledCheckBox >
              <input
                type="checkbox"
              />
              { (this.props.quizScore && this.props.quizScore.quiz_status) 
                ?
                <div>
                  <CheckMark checked={option.user_choice} />
                  <span className={option['is_correct'] ? 'green' : option.user_choice && 'red'}>{option.choice}</span>
                </div>
                : list && list.no_of_correct_choices === 1 ?
                  <div onClick={() => this.markAnswer(option.id, questionIndex, list)}>
                    <CheckMark checked={option.id === this.state.optionSelection[questionIndex]['choices'][0]}/>
                    <span>{option.choice}</span>
                  </div>
                  :
                  <div onClick={() => this.markAnswer(option.id, questionIndex, list)}>
                    <CheckMark checked={ this.multipleChoices(option.id, questionIndex)}/>
                    <span>{option.choice}</span>
                  </div>
              }
            </StyledCheckBox>
          </OptionsBlock>
        ))}
      </QuestionContainer>
    ))
  );

  goBack = () => {
    const { match, history } = this.props;
    history.push(`/education/adventure/${match.params.categoryName}/article/${match.params.id}`);
  };

  render() {
    const { adventureArticle, quizScore, loading } = this.props;
    const { quizQuestions, buttonDisable } = this.state;
    if(!adventureArticle || !quizQuestions) {
      return <Waiting />
    }
    return (
      <Layout>
        <TitleContainer>
          <Title mainTitle>{adventureArticle.category_name}</Title>
          <AdventureTitle>{adventureArticle.title}</AdventureTitle>
        </TitleContainer>
        <ArticleButtonsContainer right>
          <BackButton onClick={() => this.goBack()}>
            <img src="/public/images/educationAdventureIcons/back-arrow.png" alt="back-arrow" />
            Back
          </BackButton>
        </ArticleButtonsContainer> 
        <QuizContainer>
          <div>
            { quizQuestions.length > 0 && 
              <div>
                <div className="title">Quiz Questions</div>
                { this.quizDashBoard() }
                <div className="buttonWrapper">
                  { !quizScore ?
                    // <SaveButton disabled={buttonDisable || loading} onClick={this.submitQuiz}>
                    //   Submit Quiz
                    // </SaveButton>
                    <CommonButton
                      btnType={"rounded"}
                      disabled={buttonDisable || loading}
                      onClick={this.submitQuiz}
                      title={"Submit Quiz"}
                    />
                    :
                    <ScoreBoard>
                      <div>
                         Score
                      </div>
                      <div className="score">
                        {`${quizScore.correct_questions_count}/${quizScore.total_questions_count}`}
                      </div>
                    </ScoreBoard>
                  }
                </div>
              </div>
            }
          </div>
        </QuizContainer>

        { quizQuestions.length <= 0 &&
          <Flex marginTop="35px" textAlign={1}>
            <NoComments> No Quizzes</NoComments>
          </Flex>
        }
      </Layout>
    );
  }
}

ArticleQuiz.propTypes = {
  adventureArticle: PropTypes.object,
  history: PropTypes.object,
  role: PropTypes.string.isRequired,
  quizQuestions: PropTypes.array,
  fetchQuizzes: PropTypes.func.isRequired,
  submitQuiz: PropTypes.func.isRequired,
  quizScore: PropTypes.object,
  fetchAdventureArticle: PropTypes.func.isRequired,
  match: PropTypes.object,
  loading: PropTypes.bool
}

const mapStateToProps = (state) => ({
  adventureArticle: state.education.adventureArticle,  
  quizQuestions: state.education.quizQuestions,
  quizScore: state.education.quizScore,
  loading: state.education.isLoading
});

const mapDispatchToProps = (dispatch) => ({
  fetchQuizzes:(id, value) => dispatch(FetchQuizzes(id, value)),
  submitQuiz: (data, categoryName, push) => dispatch(SubmitQuiz(data, categoryName, push)),
  fetchAdventureArticle: (id, adventureCategory) => dispatch(getAdventureArticle(id, adventureCategory))
})

export default connect(mapStateToProps, mapDispatchToProps)(ArticleQuiz);