import styled from 'styled-components';

const ProximaBold = 'Proxima-Nova-Bold';

const QuestionContainer = styled.div`
  width: 100%;
  height: 100%;
  float: left;
  margin: 15px 0px;
  .fas {
    margin-right: 0px;
  }
  .dropdown-toggle {
    width: 32px;
    height: 32px;
  }
`;

const QuizContainer = styled.div`
  display: block;
  float: left;
  width: 100%;
  .title {
    letter-spacing: 0px;
    color: rgb(51,51,51);
    margin-top: 20px;
    font-size: 18px;
  }
  .buttonWrapper {
    display: flex;
    align-items: center;
    float: left;
    width: 100%;
    > button {
      margin: 10px;
    }
  }
`;

const OptionsBlock = styled.div`
  width: 100%;
  display: block;
  float: left;
  padding: 0px 10px;
  .green {
    color: green;
  }
  .red {
    color: red;
  }
  .glyphicon-ok {
    color: green;
    margin-left: 5px;
    margin-top: 2px;
  }
  .glyphicon-remove {
    color: red;
    margin-left: 5px;
    margin-top: 2px;
  }
`;

const AddQuizDashboard = styled.div`
  padding: 20px 10px;
  float: left;
  width: 100%;
`;

const OptionCircle = styled.div`
  float: right;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  .circle {
    width: 30px; 
    height: 30px;
    display: flex;
    margin-left: ${({ marginLeft }) => marginLeft ? marginLeft : '0px'};
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    background-color: ${({ bgColor }) => bgColor ? bgColor : '#ef6827'};
    margin-top: ${({ marginTop }) => marginTop ? marginTop : '0px'}
    .glyphicon {
      top: 0px;
    }
  }
`;

const Flex = styled.div`
  display: flex;
  width: ${({ noWidth }) => noWidth ? noWidth : '100%' };
  justify-content: ${({ noCenter }) => !noCenter && 'center'};
  align-items: ${({ noAlign }) => !noAlign && 'center'};
  height: 100%;
  float: left;
  margin-top: ${({ marginTop }) => marginTop && marginTop };
  margin-left: ${({ marginLeft }) => marginLeft && marginLeft };
  margin-bottom: ${({ marginBottom }) => marginBottom && marginBottom };
  font-size: ${({ fontSize }) => fontSize && fontSize };
  text-align: ${({ textAlign }) => textAlign && 'center' };
  .addMore {
    display: inline-block;
    margin-left: 10px;
    margin-top: 13px;
    color: #93bf3d;
    text-decoration: underline;
    cursor: pointer;
  }
  .header {
    color: #159fc9;
  }
  > button {
    margin: 10px;
  }
  .question {
    width: ${({ role }) => role ? '90%' : '100%'};
    display: inline-block;
    padding: 10px;
  }
  .edit {
    width: 10%;
    display: inline-block;
    text-align: right;
  }
  @media(max-width: 600px) {
    width: 100%;
    margin-left: 0px;
  }
`;

const Square = styled.div`
  width: 44px; 
  height: 44px;
  background-color:  ${({ isChecked }) => isChecked ? '#b196d4' : 'black'};
  color: white;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  margin-top: 12px;
  margin-right: 20px;
`;

const OptionContainer = styled.div`
  min-width: 350px;
  max-width: 600px;
  @media (max-width: 600px) {
    min-width: 100px;
  }
`;

const ScoreBoard = styled.div`
  cursor: not-allowed;
  height: 60px;
  background-color: #9cec97;
  width: 240px;
  margin-bottom: 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 25px;
  font-family: ${ProximaBold};
  .score {
    margin-left: 10px;
  }
`;

const Layout = styled.div`
  width: 100%;
  .float {
    width; 100%;
    float; left;
  }
>div:first-child{
  width: 67.1%;
}
  @media (max-width: 500px) {
    padding: 0 10px 30px;
  }
`;

export { ScoreBoard, OptionContainer, Square, Flex, OptionCircle, AddQuizDashboard, QuestionContainer, QuizContainer, OptionsBlock, Layout };